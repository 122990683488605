import { css } from '@emotion/react';
import React from 'react';

import styled from '@emotion/styled';
import LogoSvg from './logo.svg';
import LogoDarkSvg from './logo.dark.svg';
import IconSvg from './icon.svg';
import IconDarkSvg from './icon.dark.svg';

interface LogoProps {
  asIcon?: Boolean
  darkBg?: Boolean
  [x: string]: any
}

export const Logo = (props: LogoProps) =>
  props.darkBg
    ? <img src={props.asIcon ? IconDarkSvg : LogoDarkSvg} {...props} />
    : <img src={props.asIcon ? IconSvg : LogoSvg} {...props} />;
