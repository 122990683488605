import React from 'react';
import { css } from '@emotion/react';
import config from '../../website-config';
import { Logo } from '../logo';

const SubscribeLogo = () => (
  <Logo
    css={SubscribeOverlayLogo}
    className="subscribe-overlay-logo"
    alt={config.title}
    darkBg
  />
);

const SubscribeOverlayLogo = css`
  position: fixed;
  top: 23px;
  left: 30px;
  height: 30px;
`;

export default SubscribeLogo;
